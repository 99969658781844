'use client';

import HTMLReactParser from 'html-react-parser';
import { Button } from '@get-mosh/msh-shared';
import LayoutBasic from 'src/components/Layout/Basic';
import styles from './error.module.scss';
import { useTrackPage } from 'src/analytics/Events';
import { PageType } from 'src/analytics/EventProps';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { datadogRum } from '@datadog/browser-rum';

type ErrorProps = {
    type: string;
    title: string | JSX.Element;
    message: string | JSX.Element;
    ctaText: string;
    ctaUrl: string;
};

const Error = ({ type, title, message, ctaText, ctaUrl }: ErrorProps) => {
    const searchParams = useSearchParams();

    const errorTitle = searchParams?.get('error_title') ?? title;
    const errorMessage = searchParams?.get('error_message') ?? message;
    const errorType = searchParams?.get('error_type') ?? type;
    const errorCtaText = searchParams?.get('cta_text') ?? ctaText;
    const errorCtaUrl = searchParams?.get('cta_url') ?? ctaUrl;

    useTrackPage('Error', {
        page_type: PageType.ERROR,
        title: errorTitle as string,
        error_type: errorType,
        error_message: errorMessage.toString(),
    });

    useEffect(() => {
        datadogRum.addError(errorType);
    }, [errorType]);

    return (
        <LayoutBasic className={styles['error']}>
            <div className={styles['error--message']}>
                <h4>Sorry!</h4>
                <h1>{errorTitle}</h1>
                <p>
                    {typeof errorMessage === 'string'
                        ? HTMLReactParser(errorMessage)
                        : errorMessage}
                </p>
            </div>
            <Button
                className={styles['error--button']}
                text={errorCtaText}
                as="a"
                href={errorCtaUrl}
            />
        </LayoutBasic>
    );
};

export default Error;
